<template>
  <Containers class="w-full" :status="status" @funEnd='funEnd' :active='5' >

    <div class="w">
      <!-- banner开始 -->
      <div class="w">
        <banner class="w-full" title="RECRUIT" bgImg="https://s3.moke.cloud/shuangmei/ebd0134e-b3a4-4113-afff-7ddbb91ea393.jpg" ></banner>
      </div>
      <!-- banner结束 -->

      <!-- 市场前景 开始 -->
      <div class="join01 w row p-0 m-0 bg-white" >
        <steps class="container-left col-md-1 p-0 m-0" num='01' title='市场前景' color='#CA4938' ></steps>
        <div class="col-md-1 p-0 m-0 pc-show"></div>
        <div class="center col-md-10 p-0 m-0 text-blue">
          <div class="wow fadeInUp">餐饮行业为我国第三产业的支柱产业，饮品行业尤为突出，高毛利、高回报率，扩张速度极快，中国饮品行业远未饱和。</div>
          <div class="mt-15 wow fadeInUp">产品造型传统复古，源于台湾古早味饮品，主材为绿豆、红豆、地瓜等，健康，无防腐剂，产品差异化，网红爆品，深受大家喜爱。</div>
          <div class="vista">
            <a-row class="vista-row">
              <a-col v-for="(item,index) in vista" :key="index" class="vista-item" :xs="8" :sm="8" :md="4">
                <div class="vista-item-num text-center text-red">{{item.num}}</div>
                <div class="vista-item-title text-center text-blue">{{item.title}}</div>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
      <!-- 市场前景 结束 -->

      <!-- 加盟优势 开始 -->
      <div class="join02 w row p-0 m-0" >
        <steps class="container-left col-md-1 p-0 m-0" num='02' title='加盟优势' color='#fff' ></steps>
        <div class="center col-md-10 p-0 m-0 ">
          <a-row class="advantage-row" type="flex" >
            <a-col v-for="(item,index) in advantage" class="advantage-item-box" :key="index" :xs="12" :sm="12" :md="8">
              <div class="advantage-item text-white wow fadeInUp" :data-wow-delay="index/10 + 's'">
                <iconFont class="icon" :icon='item.icon' color='#fff' :size='48' ></iconFont>
                <div class="advantage-item-title text-center">{{item.title}}</div>
                <div class="advantage-item-details text-center">{{item.details}}</div>
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="col-md-1 p-0 m-0 pc-show"></div>
      </div>
      <!-- 加盟优势 结束 -->

      <!-- 品牌理念 开始 -->

      <joiningProcess class="w" num='03' :isIndex="false"  ></joiningProcess>

      <!-- 品牌理念 结束 -->

      <!-- 加盟支持 开始 -->
      <toSupport class="w" num='04' ></toSupport>
      <!-- 加盟支持 结束 -->

      <!-- 加盟支持 开始 -->
      <leaveMessage class="w" num='05' ></leaveMessage>
      <!-- 加盟支持 结束 -->
    </div>
  </Containers>
</template>

<script>
import { WOW } from 'wowjs'
import banner from '@/components/banner'
import steps from '@/components/steps'
import toSupport from '@/components/toSupport'
import joiningProcess from '@/components/joiningProcess'
import leaveMessage from '@/components/leaveMessage'

import iconFont from '@/components/iconFont'

export default {
  metaInfo: {
    title: '招商加盟_双美豆沙牛乳官方网站',
    meta: [
      {
        name: 'keywords',
        content: '双美豆沙牛乳招商加盟_双美豆沙牛乳官网'
      },
      {
        name: 'description',
        content: '本页面为您提供双美豆沙牛乳联系方式，您可以在本页面留言或者咨询客服联系我们！'
      }
    ]
  },
  data () {
    return {
      status: 'success',
      vista: [
        {
          num: 12,
          title: '饮品行业年增长速度超过12%'
        },
        {
          num: 268,
          title: '平均268人就拥有一家餐饮店'
        },
        {
          num: 2000,
          title: '我国现约2000人有一家饮品店'
        }
      ],
      advantage: [
        { icon: 'Invest', title: '投资门槛低', details: '采取直营加连锁模式，所有的营销方案都是先直营后连锁，确保营销方案成熟有效。' },
        { icon: 'storefront', title: '店面位置小', details: '专业的事情交给我们，您要做的只是轻松管理。' },
        { icon: 'payback', title: '成本回收速度快', details: '7天内即可学完全部课程，上岗就是熟手，上岗就能轻松销售，约上二到三个小伙伴就能开店。' },
        { icon: 'address', title: '选址多样化', details: '区域式经营，不怕跟风，不怕模仿，完全的区域保护措施，保障每一个合作伙伴的利益。' },
        { icon: 'produced', title: '出品操作便捷，爆款无同质化竞品', details: '迅速回收成本，助您快速积累资金与运营经验，同步积累客户，快速复制新店。' },
        { icon: 'acting', title: '开放代理，迅速扩张', details: '开放省、地市、县级代理权限，轻松占领城市区域市场。' },
      ]
    }
  },
  mounted () {
    this.getInit()
  },
  components: {
    banner,
    iconFont,
    steps,
    toSupport,
    joiningProcess,
    leaveMessage,
  },
  computed: {
  },
  methods: {
    funEnd () {
      // setTimeout(() => {
      //   this.status = 'success'
      //   this.$nextTick(() => {
      //     // 在dom渲染完后,再执行动画
      //     const wow = new WOW({
      //       live: false
      //     })
      //     wow.init()
      //   })
      // }, 300)

    },
    async getInit () {
      try {
        setTimeout(() => {
          this.$nextTick(() => {
            // 在dom渲染完后,再执行动画
            const wow = new WOW({
              live: false
            })
            wow.init()
          })
        }, 0)
      } catch (e) {
        this.status = 'error'
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/mixin1700.scss';

.join01{
  height: 600px;
  @include hamburger {
    height: auto;
    padding: 0 12px 50px !important;
  }
  .center{
    padding-top: 130px !important;
    @include hamburger {
      padding-top: 0px !important;
    }
  }
  .vista{
    margin-top: 65px;
    .vista-row{
      .vista-item{
        padding: 0 2vw !important;
        position: relative;
        &::after{
          content: '';
          width: 0;
          height: 92px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          border: 1px solid rgba(71, 102, 133, 0.1);
        }
        &:last-child{
          &::after{
            border: unset;
          }
        }
        .vista-item-num{
          font-size: 28px;
          font-weight: bold;
        }
        .vista-item-title{
          font-size: 15px;
          margin-top: 5px;
        }
      }
    }
  }
}
.join02{
  background: #CA4938 url('https://s3.moke.cloud/shuangmei/bf5394c8-432d-460c-afb3-6a28b12dd2a9.jpg') no-repeat center center;
  background-size: cover;
  @include hamburger {
    height: auto;
    // margin-top: 100px !important;
  }
  .center{
    padding: 140px 0 !important;
    @include hamburger {
      padding: 0px 9px !important;
    }
  }
  .advantage-row{
    .advantage-item-box{
      padding: 1.3vw !important;
      @include hamburger {
        padding: 5px !important;
      }
    }
    .advantage-item{
      height: 100%;
      padding: 60px 48px !important;
      background-color: rgba(30, 69, 108, 0.8);
      @include hamburger {
        padding: 12px !important;
      }
      .icon{
        margin: 0 auto !important;
      }
      .advantage-item-title{
        font-size: 20px;
        font-weight: bold;
        margin-top: 10px;
        @include hamburger {
          font-size: 14px;
        }
      }
      .advantage-item-details{
        margin-top: 20px;
        @include hamburger {
          font-size: 13px;
          margin-top: 5px;
        }
      }
    }
  }
}

.join03{
  @include hamburger {
    height: auto;
  }
  .center{
    padding: 60px 0 0!important;
    @include hamburger {
      padding: 0px !important;
    }
  }
}

.container-left{
  @include hamburger {
    height: auto !important;
  }
}
.about-content{
  p{
    margin-bottom: 15px;
    @include hamburger {
      margin-bottom: 0px;
    }
  }
}
</style>
